<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('adsoForm')" @cancel="cancel" :adsoeFlag="true"></add-header>
    <el-form ref="adsoForm" :model="adsoForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="付款单位" prop="cust_id">
            <el-select disabled v-model="adsoForm.cust_id" size="small" placeholder="请选择付款单位" filterable>
              <el-option v-for="item in custOptionList" :key="item.cust_id" :label="item.cust_name" :value="item.cust_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="出运发票号" prop="tran_no">
            <el-input v-model="adsoForm.tran_no" placeholder="请填写合同金额" maxlength="255" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款日期:" prop="adso_date">
            <el-date-picker v-model="adsoForm.adso_date" type="date" placeholder="选择收款日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="合同金额" prop="adso_total">
            <el-input v-model="adsoForm.adso_total" placeholder="请填写合同金额" maxlength="13" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="币种" prop="cust_currency">
            <el-input v-model="adsoForm.cust_currency" disabled placeholder="请填写币种" maxlength="20" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="汇率" prop="tran_usdrate">
            <el-input v-model="adsoForm.tran_usdrate" disabled placeholder="请填写汇率" maxlength="30" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="本币金额" prop="scon_rmbtotal">
            <el-input v-model="adsoForm.scon_rmbtotal" disabled placeholder="请填写本币金额" maxlength="255" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_id">
            <el-select @change="cpttChange" v-model="adsoForm.cptt_id" size="small" placeholder="请选择公司抬头" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="银行费用" prop="adso_chartotal">
            <el-input v-model="adsoForm.adso_chartotal" disabled placeholder="请填写银行费用" maxlength="20" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="发布金额" prop="adso_issu">
            <el-input v-model="adsoForm.adso_issu" disabled placeholder="请填写发布金额" maxlength="255" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款银行" prop="cptt_bank_name">
            <el-select
              @change="bankChange"
              :disabled="!adsoForm.cptt_id"
              v-model="adsoForm.cptt_bank_name"
              size="small"
              placeholder="请选择收款银行"
              filterable
            >
              <el-option v-for="item in bankList" :key="item.cptt_bank_id" :label="item.cptt_bank_name" :value="item.cptt_bank_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="银行账号" prop="cptt_bank_account">
            <el-input v-model="adsoForm.cptt_bank_account" disabled placeholder="请填写银行账号" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="已冲金额" prop="adso_reved">
            <el-input v-model="adsoForm.adso_reved" disabled placeholder="请填写已冲金额" maxlength="30" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="未冲金额" prop="adso_unreve">
            <el-input v-model="adsoForm.adso_unreve" disabled placeholder="请填写未冲金额" maxlength="30" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="付款方式" prop="cust_payway">
            <el-select v-model="adsoForm.cust_payway" size="small" placeholder="请选择付款方式" filterable>
              <el-option v-for="item in CompanyList" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="实收金额" prop="adso_netrece_total">
            <el-input v-model="adsoForm.adso_netrece_total" disabled maxlength="30" placeholder="请填写实收金额" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <el-button type="primary" plain size="small" @click="openCollect">导入应收款冲销</el-button>
            <el-button type="danger" plain size="small" @click="deleteCollect">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <AdsoReceList :adsoForm="adsoForm" @adsoNetreceChange="adsoNetreceChange" @handleSelectionChange="handleSelectionChange"></AdsoReceList>
        </el-col>
      </el-row>
      <el-dialog title="导入出运发票号" width="70%" :visible.sync="dialogTableVisible">
        <CollecList ref="CollecList" @childChanel="childChanel" @childConfirm="childConfirm"></CollecList>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { adsoAPI } from '@api/modules/adso';
import { cpttAPI } from '@api/modules/comptitle';
import { custAPI } from '@/api/modules/cust';
import { optnAPI } from '@api/modules/optn';
import helper from '@assets/js/helper';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import CollecList from './Component/CollecList.vue';
import AdsoReceList from './Component/AdsoReceList.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'AdsoAddMain',
  components: {
    addHeader,
    inputUser,
    AdsoReceList,
    CollecList
  },
  data() {
    return {
      rules: {
        tran_no: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      adsoForm: {
        tran_no: null,
        tran_id: null,
        cust_id: null,
        tran_date: new Date(),
        cptt_bank_name: null,
        cptt_id: null,
        adso_adsoe: 0,
        adso_remark: null,
        adso_rece_list: []
      },
      custOptionList: [],
      cpttList: [],
      dialogTableVisible: false,
      selectList: [],
      bankList: [],
      CompanyList: []
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getCptt();
      this.initCust();
      this.getCustDport();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let adsoForm = JSON.parse(JSON.stringify(this.adsoForm));
      adsoForm = Object.assign(adsoForm, staffForm);
      if (adsoForm.tran_date) {
        adsoForm.tran_date = parseInt(Number(new Date(adsoForm.tran_date).getTime()) / 1000);
      }
      post(adsoAPI.addAdso, adsoForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump('/adso_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });

            this.resetForm('adsoForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('adsoForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 打开弹框
    openCollect() {
      this.dialogTableVisible = true;
      setTimeout(() => {
        this.$refs.CollecList.custId = this.adsoForm.cust_id;
        this.$refs.CollecList.initData();
      }, 300);
    },
    // 关闭弹框
    childChanel() {
      this.dialogTableVisible = false;
    },
    // 添加内容
    childConfirm(val) {
      let list = JSON.parse(JSON.stringify(val));
      list = list.filter(item => this.adsoForm.adso_rece_list.every(item1 => item.tran_part_id !== item1.tran_part_id));
      for (let i = 0; i < list.length; i++) {
        list[i].adso_subrece = this.helper.haveFour(list[i].prod_subtotal);
        list[i].adso_reced_total = this.helper.haveFour(list[i].segi_claied);
        let totalA = new BigNumber(0);
        totalA = totalA.plus(list[i].adso_subrece ? list[i].adso_subrece : 0).minus(list[i].adso_reced_total ? list[i].adso_reced_total : 0);
        totalA = totalA.toNumber();
        list[i].adso_unreced = this.helper.haveFour(totalA);
        list[i].adso_rece_reved = null;
        list[i].adso_rece_char = null;
        list[i].adso_netrece = null;
        list[i].adso_rece_remark = null;
      }
      this.adsoForm.adso_rece_list = this.adsoForm.adso_rece_list.concat(list);
      this.calcEcho();
      this.dialogTableVisible = false;
    },
    // 删除内容
    deleteCollect() {
      if (this.selectList.length < 1) {
        return this.$message.warning('至少选择一条数据');
      }
      this.adsoForm.adso_rece_list = this.adsoForm.adso_rece_list.filter(item => this.selectList.every(item1 => item.tran_part_id !== item1.tran_part_id));
      this.calcForm();
    },
    // 公司回显银行选项
    cpttChange(val) {
      console.log(1111);
      for (let i = 0; i < this.cpttList.length; i++) {
        if (this.cpttList[i].cptt_id === this.adsoForm.cptt_id) {
          this.bankList = this.cpttList[i].cptt_bank_list;
          this.adsoForm.cptt_bank_name = null;
        }
      }
    },
    // 回显银行账户
    bankChange() {
      console.log('2222', this.adsoForm.cptt_bank_name);
      for (let i = 0; i < this.bankList.length; i++) {
        if (this.bankList[i].cptt_bank_name === this.adsoForm.cptt_bank_name) {
          this.adsoForm.cptt_bank_account = this.bankList[i].cptt_bank_code;
        }
      }
    },
    // 计算
    adsoNetreceChange() {
      this.calcForm();
    },
    // 选择子表
    handleSelectionChange(val) {
      this.selectList = JSON.parse(JSON.stringify(val));
    },
    calcForm() {
      let totalA = new BigNumber(0);
      let totalB = new BigNumber(0);
      let totalC = new BigNumber(0);
      for (let i = 0; i < this.adsoForm.adso_rece_list.length; i++) {
        totalA = totalA.plus(this.adsoForm.adso_rece_list[i].adso_rece_reved ? this.adsoForm.adso_rece_list[i].adso_rece_reved : 0);
        totalB = totalB.plus(this.adsoForm.adso_rece_list[i].adso_rece_char ? this.adsoForm.adso_rece_list[i].adso_rece_char : 0);
        totalC = totalC.plus(this.adsoForm.adso_rece_list[i].adso_netrece ? this.adsoForm.adso_rece_list[i].adso_netrece : 0);
      }
      totalA = totalA.toNumber();
      totalB = totalB.toNumber();
      totalC = totalC.toNumber();
      this.adsoForm.adso_reved = this.helper.haveFour(totalA);
      this.adsoForm.adso_chartotal = this.helper.haveFour(totalB);
      this.adsoForm.adso_netrece_total = this.helper.haveFour(totalC);
      let totalD = new BigNumber(1);
      let totalE = new BigNumber(0);
      totalD = totalD.times(this.adsoForm.tran_usdrate ? this.adsoForm.tran_usdrate : 0).times(this.adsoForm.adso_reved ? this.adsoForm.adso_reved : 0);
      totalE = totalE.plus(this.adsoForm.adso_issu ? this.adsoForm.adso_issu : 0).minus(this.adsoForm.adso_reved ? this.adsoForm.adso_reved : 0);
      totalD = totalD.toNumber();
      totalE = totalE.toNumber();
      this.adsoForm.scon_rmbtotal = this.helper.haveFour(totalD);
      this.adsoForm.adso_unreve = this.helper.haveFour(totalE);
    },
    // 获取客户
    initCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取币种
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10013 })
        .then(res => {
          if (res.data.code === 0) {
            this.CompanyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #c2c2c2;
  margin: 10px 0;
}
</style>
